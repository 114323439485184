// 我的问题工单
// sle
<template>
  <div class="main flex-column" :style="{ minHeight: minHeight + 'px' }">
    <page-head title="我的提问" />
    <!-- <div class="feedback">
      <a-button @click="feedback">工单反馈</a-button>
    </div> -->
    <div class="middle flex-row align-center justify-between">
      <a-input-search
        class="search"
        v-model:value="pagination.searchInput"
        placeholder="搜索"
        allow-clear
        @search="rightSearch"
        @change="rightSearchChange"
        :maxlength="20"
      >
      </a-input-search>
      <div class="selectRow flex-row align-center">
        工单状态：
        <a-select
          v-model:value="pagination.orderState"
          style="width: 200px; margin-left: 20px"
          @change="selectChange()"
        >
          <a-select-option
            v-for="r in orderStateDrop"
            :key="r.linkKey"
            :value="r.linkKey"
            >{{ r.name }}</a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="table">
      <a-spin :spinning="spinning">
        <a-table
          align="center"
          :columns="columns"
          :data-source="list"
          :rowKey="(record) => record.id"
          :pagination="pagination"
          @change="paginationChange"
          :scroll="{ x: 1300 }"
          bordered
        >
          <template #orderDetails="{ record }">
            <a-tooltip>
              <template #title>{{ record.orderDetails }}</template>
              <span class="ecllipsis" style="width: 100%">
                {{ record.orderDetails }}
              </span>
            </a-tooltip>
          </template>
          <template #orderTitle="{ record }">
            <a-tooltip>
              <template #title>{{ record.orderTitle }}</template>
              <span class="ecllipsis" style="width: 100%">
                {{ record.orderTitle }}
              </span>
            </a-tooltip>
          </template>
          <template #operation="{ record }">
            <div class="rowclick">
              <a @click="rowclick(record)">查看记录 </a>
              <a
                v-if="record.orderStateName !== '已关闭'"
                @click="dismissConfirm(record)"
                style="margin-left: 10px"
                >关闭工单</a
              >
            </div>
          </template>
        </a-table>
      </a-spin>
      <a-modal
        v-model:visible="visibleDismiss"
        title="提示"
        ok-text="确认"
        :maskClosable="false"
        cancel-text="取消"
        @ok="orderClose"
        :confirmLoading="confirmLoading"
        @cancel="dismissCancel"
      >
        <a-spin :spinning="spinning">
          <span style="display: inline-block; padding: 10px 0"
            >是否确定关闭该问题工单</span
          >
        </a-spin>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance } from 'vue'
import api from '@/api/API'
import { minheight } from '../../components/sameVariable'
import PageHead from '@/components/PageHead'
import { isEmpty } from '@/assets/common.js'
import { getTableColumnData } from '@/api/tableAbout/tableAPI'

export default defineComponent({
  components: {
    'page-head': PageHead,
  },
  data () {
    return {
      closeApplicationView: false,
      loading: false, // 是否正在开启/关闭应用
      spinning: false, // 是否加载
      list: [], // table数据
      columns: [], // table列
      orderStateDrop: [], // 工单状态下拉
      pagination: {
        current: 1,
        pageSize: 10,
        total: 100,
        orderState: null, // 工单状态
        searchInput: '', // 搜索内容
        isSearch: false, // 是否正在搜索
      }, // 分页部分
      selectedRows: [], // 当前选中部分
      visibleDismiss: false, // 弹窗是否可见
      confirmLoading: false, // 弹窗按钮是否加载
      tempRecord: {}, // 弹窗传参中转
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    this.getTableColumnData()
    this.getTableData()
    this.getOrderStateSelectList()
  },
  // 方法
  methods: {
    rowclick (record) {
      const obj = {
        orderCode: record.orderCode,
        id: record.id,
      }
      this.$router.push({ path: '/UserManagement/MyQuestions/Feedback', query: obj })
    },
    orderClose () {
      this.confirmLoading = true
      const obj = {
        id: this.tempRecord.id,
      }
      api
        .post('/api/app/sys-problem-order/close-work-order', obj)
        .then(res => {
          this.confirmLoading = false
          this.visibleDismiss = false
          this.$message.success('关闭成功！')
          this.minHeight = minheight
          this.getTableColumnData()
          this.getTableData()
          this.getOrderStateSelectList()
        })
        .catch(err => {
          this.confirmLoading = false
          this.visibleDismiss = false
          console.log(err)
        })
    },
    feedback () {
      this.$router.push({ path: '/UserManagement/MyQuestions/Feedback' })
    },
    dismissCancel () {
      this.visibleDismiss = false
    },
    dismissConfirm (record) {
      this.visibleDismiss = true
      this.tempRecord = record
    },
    // 获取当前table的列信息
    async getTableColumnData () {
      const data = await getTableColumnData('MyQuestions', 'userManagement', true)
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取列结构失败')
          console.log(err)
        })
      this.columns = data
    },
    // 获取工单状态列表
    getOrderStateSelectList () {
      api
        .get('/api/app/sys-problem-order/sys-problem-order-state-select-list')
        .then(({ data }) => {
          const obj = { linkKey: '', code: 'all', name: '全部' }
          data.splice(0, 0, obj)
          this.orderStateDrop = data
        })
        .catch(err => {
          // this.$message.error('获取工单状态数据失败')
          console.log(err)
        })
    },
    // 获取table数据
    getTableData () {
      this.spinning = true
      api
        .get('/api/app/sys-problem-order/user-paging-sys-problem-order-list', {
          params: {
            PageSize: this.pagination.pageSize,
            Pagination: this.pagination.current,
            OrderState: this.pagination.orderState,
            SearchInput: this.pagination.searchInput,
          },
        })
        .then(({ data }) => {
          this.spinning = false
          this.list = data.list
          this.pagination.total = data.pageCount
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取数据失败')
          console.log(err)
        })
    },
    // 选项变更事件
    selectChange () {
      this.pagination.current = 1
      this.getTableData()
    },
    // 页码变更事件
    paginationChange (e) {
      this.pagination.current = e.current
      this.getTableData()
    },
    // 搜索内容变化
    rightSearchChange () {
      if (isEmpty(this.pagination.searchInput) && this.pagination.isSearch) {
        this.pagination.isSearch = false
        this.pagination.current = 1
        this.getTableData()
      }
    },
    // 搜索按钮
    rightSearch (e) {
      if (isEmpty(this.pagination.searchInput)) {
        return
      }
      this.pagination.current = 1
      this.pagination.isSearch = true
      this.getTableData()
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";

.main {
  position: relative;
  background: #fff;
}
.ecllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.feedback {
  position: absolute;
  right: 20px;
  top: 16px;
}
.middle {
  width: 100%;
  padding: 0px 20px;
  height: 80px;
  .search {
    font-size: 13px;
    width: 300px;
  }

  .selectRow {
    font-size: 13px;
  }
  .right {
    .button {
      margin-left: 20px;
      width: 90px;
    }
  }
}
.rowclick {
  margin: 10px 10px;
  cursor: pointer;
  color: #134ccf;
}
.table {
  padding: 0px 20px;
}
::v-deep(.ant-table-bordered .ant-table-thead) > tr > th {
  text-align: center;
  border-right: none;
  padding: 12px 10px;
}
::v-deep(.ant-table-bordered .ant-table-tbody) > tr > td {
  text-align: center;
  padding: 12px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-right: none;
}
// ::v-deep(.ant-table-bordered .ant-table-fixed-right) table {
//   border-right: 1px solid #f0f0f0;
// }
</style>
